<template>
  <SimpleModal
    :title="$t('truckOffer.applicationModal.title')"
    :show="show"
    width-class="max-w-lg w-full lg:max-w-6xl"
    @hide="closeModal"
  >
    <span class="text-gray-500">
      {{ $t('truckOffer.applicationModal.sub') }}
    </span>
    <TrForm
      id="truckOffer-application-form"
      class="flex flex-col space-y-4"
      @validated="submit"
    >
      <div
        v-if="availablePaymentSchemes.length > 1"
        class="w-full lg:w-1/3 mt-8"
      >
        <LabeledSelect
          v-model="paymentScheme"
          :rules="[required()]"
          :label="$t('tender.create.form.paymentScheme')"
          :items="paymentSchemeOptions"
          name="payment-scheme"
        />
      </div>

      <div class="w-full lg:w-1/3 mt-8">
        <TrLabeledInput
          v-if="paymentScheme"
          v-model="paymentAmountPerHour"
          :rules="[required(), positiveDecimal()]"
          min="1"
          :label="$t('truckOffer.applicationModal.paymentAmount') + ' ' + $t('tender.paymentScheme.' + paymentScheme.toLowerCase())"
          type="text"
          name="payment-amount"
        />
      </div>

      <div
        v-if="truckOfferPaymentAmountDiff < 0"
        class="flex items-center"
      >
        <InformationCircleIcon
          class="h-6 w-6 text-blue-600"
          aria-hidden="true"
        />
        <span class="ml-2">
          {{ $t('truckOffer.applicationModal.paymentAmountLessThanTruckOffer') }}
        </span>
      </div>

      <TrTextarea
        v-model="message"
        name="message"
        :label="$t('truckOffer.applicationModal.message')"
      />
    </TrForm>

    <Alert
      v-if="!!estimateCosts?.toNumber()"
      class="mt-4"
      :title="$t('truckOffer.applicationModal.costInfo.head')"
      :mode="AlertMode.INFO"
    >
      <template #sub>
        {{ $t('truckOffer.applicationModal.costInfo.explain1') }} <TrMoney
          v-if="estimateCosts"
          :value="estimateCosts.abs()"
        /> {{ $t('truckOffer.applicationModal.costInfo.explain2') }}
      </template>
    </Alert>

    <template #actions>
      <div class="mb-4 sm:mb-0">
        <TrButton
          class="w-full"
          type="submit"
          form="truckOffer-application-form"
          name="truck-offer-apply"
        >
          {{ $t('generic.send') }}
        </TrButton>
      </div>
      <div class="sm:mr-4">
        <TrButton
          class="w-full"
          :color-scheme="ButtonColorScheme.TERTIARY"
          @click="closeModal"
        >
          {{ $t('generic.close') }}
        </TrButton>
      </div>
    </template>
  </SimpleModal>
</template>

<script lang="ts" setup>
import SimpleModal from '@app/overlays/modals/SimpleModal.vue';
import {Uuid} from '@app/uuid/Uuid';
import {userCompanyId} from '@app/auth/Auth';
import TrButton from '@app/support/TrButton.vue';
import {ButtonColorScheme} from '@app/support/ButtonColorScheme';
import TrForm from '@app/forms/TrForm.vue';
import {computed, ref, toRefs, watchEffect} from 'vue';
import TrTextarea from '@app/forms/TrTextarea.vue';
import {required} from '@app/forms/rules/required';
import TrLabeledInput from '@app/forms/LabeledInput.vue';
import {PaymentScheme} from '@app/tender/PaymentScheme';
import {Money} from '@app/money/Money';
import {InformationCircleIcon} from '@heroicons/vue/24/outline';
import {positiveDecimal} from '@app/forms/rules/positiveDecimal';
import Alert from '@app/feedback/Alert.vue';
import {AlertMode} from '@app/feedback/AlertMode';
import {useAsyncAction} from '@app/http/useAsyncAction';
import TrMoney from '@app/money/money/Money.vue';
import {applyForTruckOffer} from '@app/truckoffer/truckOfferService';
import {Currency} from '@app/money/Currency';
import LabeledSelect from '@app/forms/LabeledSelect.vue';
import {usePaymentSchemeOptions} from '@app/payment/usePaymentSchemeOptions';

interface Props {
    show: boolean,
    truckOfferId: Uuid,
    availablePaymentSchemes: PaymentScheme[],
    suggestedPaymentAmountPerTon?: Money
    suggestedPaymentAmountPerHour?: Money
    estimateCosts?: Money
}

interface Events {
    (e: 'hide'): void,
    (e: 'dirty'): void,
}

const props = defineProps<Props>();
const emit = defineEmits<Events>();

const {
    truckOfferId,
    suggestedPaymentAmountPerHour,
    suggestedPaymentAmountPerTon,
    availablePaymentSchemes,
} = toRefs(props);

const message = ref<string>();
const paymentAmountPerHour = ref<string>();
const paymentScheme = ref<PaymentScheme>(PaymentScheme.PER_HOUR);

watchEffect(() => {
  if (availablePaymentSchemes.value.length === 0) {
    return;
  }

  if (availablePaymentSchemes.value.includes(paymentScheme.value)) {
    return;
  }

  paymentScheme.value = availablePaymentSchemes.value[0];
});

const paymentAmountMoney = computed(() => paymentAmountPerHour.value ? Money.fromString(paymentAmountPerHour.value, Currency.EUR) : undefined);

const closeModal = () => {
    message.value = '';
    paymentAmountPerHour.value = suggestedPaymentAmountPerHour.value?.toBig().toString();
    emit('hide');
};

const {execute: submit} = useAsyncAction(async () => {
    if (!truckOfferId?.value) {
        return;
    }

    if (!userCompanyId.value) {
      throw new Error('Cannot apply for TruckOffer without company id!');
    }

    if (!paymentAmountMoney.value) {
      throw new Error('PaymentAmount is required!');
    }

    await applyForTruckOffer(truckOfferId.value, {
      contractorId: userCompanyId.value.toString(),
      message: message.value,
      paymentScheme: paymentScheme.value,
      paymentAmount: paymentAmountMoney.value?.toNumber(),
    });

    emit('dirty');
    closeModal();
});

const truckOfferPaymentAmountDiff = computed(() => {
    if (!suggestedPaymentAmount?.value) {
        return 0;
    }

    if (!paymentAmountMoney.value) {
        return 0;
    }

    return paymentAmountMoney.value.toNumber() - suggestedPaymentAmount.value.toNumber();
});

const { paymentSchemeOptions } = usePaymentSchemeOptions();

const suggestedPaymentAmount = computed(() => {
  return {
    [PaymentScheme.PER_HOUR]: suggestedPaymentAmountPerHour.value,
    [PaymentScheme.PER_TON]: suggestedPaymentAmountPerTon.value,
  }[paymentScheme.value];
});
</script>
