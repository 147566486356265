<template>
  <div class="relative flex items-start">
    <div class="flex h-5 items-center">
      <input
        :id="id"
        :disabled="disabled"
        name="comments"
        type="checkbox"
        :class="{
          'h-4 w-4 rounded': true,
          'border-gray-300 text-primary-600 focus:ring-primary-500': !disabled,
          'text-gray-400 border-gray-200': disabled,
        }"
        v-bind="inputAttrs"
      >
    </div>
    <div class="ml-3 text-sm">
      <label
        :for="id"
        class="font-medium text-gray-700"
      ><slot /></label>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {useAttrs} from 'vue';
import UuidFactory from '@app/uuid/UuidFactory';

defineOptions({
    inheritAttrs: false,
});

const inputAttrs = useAttrs();

interface Props {
  id?: string,
  disabled?: boolean
}

withDefaults(defineProps<Props>(), {
  id: () => UuidFactory.v4().toString(),
});
</script>
