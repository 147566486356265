import httpClient from '@app/http/HttpClient';
import {Uuid} from '@app/uuid/Uuid';

export interface UserPreferences {
    statusChangeNotificationEnabled: boolean
}

export interface UserPreferencesResponsePayload {
    statusChangeNotificationEnabled: boolean
}

export interface UserPreferencesRequestPayload extends UserPreferencesResponsePayload {}

export async function fetchUserPreferences(userId: Uuid): Promise<UserPreferences> {
    const userPreferencesResponse = await httpClient.get<UserPreferencesResponsePayload>(`/secure/users/${userId.toString()}/preferences/`);

    return userPreferencesResponse.data;
}

export async function updateUserPreferences(userId: Uuid, userPreferences: UserPreferences): Promise<void> {
    await httpClient.post<UserPreferencesRequestPayload>(`/secure/users/${userId.toString()}/preferences/`, userPreferences);
}
