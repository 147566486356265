import {authRoutes} from '../auth/routes';
import NotFound from '../NotFound.vue';
import {RouteRecordRaw} from 'vue-router';
import {billDetailsRoute, billsRoute} from '@app/billing/routes';
import {tenderRoutes} from '@app/tender/routes';
import {dashboardRoute} from '@app/dashboard/routes';
import {companyRoutes} from '@app/company/routes';
import {legalRoutes} from '@app/legal/routes';
import {truckOffersRoutes} from '@app/truckoffer/routes';
import {reportingRoutes} from '@app/reporting/routes';
import {userPreferencesRoutes} from '@app/preferences/routes';

export const routes: RouteRecordRaw[] = [
    ...authRoutes,

    billsRoute,
    billDetailsRoute,

    dashboardRoute,

    ...companyRoutes,

    ...tenderRoutes,

    ...truckOffersRoutes,

    ...legalRoutes,

    ...reportingRoutes,

    ...userPreferencesRoutes,

    {
        path: '/:pathMatch(.*)*',
        component: NotFound,
        meta: {
            blank: true,
            allowAnonymous: true,
        },
    },
];
