<template>
  <TrBaseGuestWrapper>
    <div class="flex justify-center">
      <div
        v-if="isFetching"
        class="flex flex-col items-center"
      >
        <TrCircleLoader />
        <p class="text-gray-600 mt-4">
          {{ $t('tender.deactivateFilterNotification.deactivating') }}
        </p>
      </div>

      <div
        v-else-if="errorMessage"
        class="flex flex-col items-center"
      >
        <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
          <ExclamationTriangleIcon
            class="h-6 w-6 text-red-600"
            aria-hidden="true"
          />
        </div>
        <p
          class="text-lg font-medium mt-4"
          v-text="errorMessage"
        />
        <div class="flex flex-col items-center mt-4 space-y-2">
          <TrButton @click="deactivate">
            {{ $t('tender.deactivateFilterNotification.errorTryAgain') }}
          </TrButton>
          <TrButton
            is-link
            to="mailto:support@transport-x.de?subject=Fehler beim Deaktivieren von E-Mail Benachrichtigungen"
            :color-scheme="ButtonColorScheme.TERTIARY"
          >
            {{ $t('tender.deactivateFilterNotification.supportMail') }}
          </TrButton>
        </div>
      </div>

      <div
        v-else
        class="flex flex-col items-center"
      >
        <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
          <CheckIcon
            class="h-6 w-6 text-green-600"
            aria-hidden="true"
          />
        </div>
        <p
          class="text-lg font-medium my-4"
          v-text="$t('tender.deactivateFilterNotification.deactivated')"
        />
        <TrButton
          is-link
          :to="{ name: hasAuthToken ? dashboardRoute.name : loginRoute.name }"
        >
          {{
            hasAuthToken ? $t('tender.deactivateFilterNotification.toDashboard') : $t('auth.register.toLogin')
          }}
        </TrButton>
      </div>
    </div>
  </TrBaseGuestWrapper>
</template>

<script lang="ts" setup>
import TrBaseGuestWrapper from '@app/shell/BaseGuestWrapper.vue';
import {CheckIcon, ExclamationTriangleIcon} from '@heroicons/vue/24/outline';
import TrCircleLoader from '@app/loader/CircleLoader.vue';
import {useAsyncAction} from '@app/http/useAsyncAction';
import {computed, ref} from 'vue';
import TrButton from '@app/support/TrButton.vue';
import {hasAuthToken, userId} from '@app/auth/Auth';
import {loginRoute} from '@app/auth/routes';
import {dashboardRoute} from '@app/dashboard/routes';
import {ButtonColorScheme} from '@app/support/ButtonColorScheme';
import {useRoute} from 'vue-router';
import {MailType, mailTypes} from '@app/preferences/MailType';
import {fetchUserPreferences, updateUserPreferences} from '@app/preferences/userPreferencesService';

const errorMessage = ref<string|undefined>();

const route = useRoute();

const mailType = computed(() => {
  const mailTypeParam = route.params.mailType;

  if (!mailTypeParam || typeof mailTypeParam !== 'string') {
    return undefined;
  }

  const mailTypeEnum = mailTypeParam.toUpperCase();

  const mailType: MailType = (mailTypes as Record<string, MailType>)[mailTypeEnum];

  return mailType;
});

const {
  execute: deactivate,
  isFetching,
} = useAsyncAction(
    async () => {
      if (!userId.value) {
        errorMessage.value = 'Melden sie sich an um diese Benachrichtigung zu deaktivieren.';
        return;
      }

      if (!mailType.value) {
        errorMessage.value = 'No MailType given';
        return;
      }

      if (mailType.value !== mailTypes.STATUS_CHANGE) {
        errorMessage.value = `MailType ${mailType.value} cannot be unsubscribed yet.`;
        return;
      }

      const userPreferences = await fetchUserPreferences(userId.value);

      await updateUserPreferences(userId.value, {
        ...userPreferences,
        statusChangeNotificationEnabled: false,
      });
    },
);

deactivate();
</script>
