<template>
  <TrLabeledInput
    :model-value="title"
    name="title"
    :label="$t('tender.create.form.title')"
    :rules="[required()]"
    @update:model-value="$emit('update:title', $event)"
  />

  <MultiFrame>
    <template #top>
      <span class="bg-white px-3 text-base leading-6 text-gray-900">Kontaktpersonen</span>
    </template>
    <div
      v-for="(participant, id) in participants"
      :key="id"
      class="flex flex-col md:flex-row md:space-x-3 space-y-6 md:space-y-0"
    >
      <div class="w-full md:w-1/2">
        <TrCompanyUsersComboBox
          :ref="(el) => addParticipantCombobox(el, id)"
          :model-value="participant.user"
          data-cy="participant-combo-box"
          :blacklist="usedParticipantsIds"
          :label="$t('tender.create.form.contact.user')"
          :rules="[required()]"
          should-default-current-user
          @update:model-value="updateParticipant(id, 'user', $event)"
        />
      </div>

      <div class="w-full md:w-1/2 flex flex-col md:flex-row">
        <div class="flex-1">
          <TenderRoleSelect
            :model-value="participant.role"
            :label="$t('tender.create.form.contact.role')"
            @update:model-value="updateParticipant(id, 'role', $event)"
          />
        </div>

        <div class="flex-none md:ml-3">
          <TrLabel
            :for="`participant-remove-${id}`"
            class="invisible"
          >
            löschen
          </TrLabel>
          <div class="mt-1">
            <TrButton
              :id="`participant-remove-${id}`"
              :disabled="!allowParticipantDeletion"
              type="button"
              :color-scheme="ButtonColorScheme.TERTIARY"
              @click="removeParticipant(id)"
            >
              <TrashIcon class="h-5" />
            </TrButton>
          </div>
        </div>
      </div>
    </div>

    <template #bottom>
      <div class="px-3 bg-white">
        <TrButton
          type="button"
          :color-scheme="ButtonColorScheme.SECONDARY"
          @click="addParticipantAndFocus"
        >
          {{ $t('tender.create.form.contact.add') }}
          <template #postfix>
            <PlusIcon class="w-5 h-5" />
          </template>
        </TrButton>
      </div>
    </template>
  </MultiFrame>

  <TrTextarea
    :model-value="description"
    name="description"
    :rules="[required()]"
    :label="$t('tender.create.form.description')"
    @update:model-value="$emit('update:description', $event)"
  />

  <div>
    <TrLabel for="date-of-execution">
      {{ $t('tender.create.form.dateOfExecution') }}
    </TrLabel>

    <DateInput
      id="date-of-execution"
      :model-value="dateOfExecution"
      name="date-of-execution"
      :rules="[required()]"
      :min-date="Calendar.fromDateTime(clock.now())"
      @update:model-value="$emit('update:dateOfExecution', $event)"
    />
  </div>

  <div class="flex flex-col md:flex-row md:space-x-3 space-y-6 md:space-y-0">
    <div class="flex-1">
      <TrLocationCombobox
        :model-value="locationOption"
        :location-details="location"
        :label="$t('tender.create.form.location')"
        :rules="[required()]"
        @update:model-value="$emit('update:locationOption', $event)"
        @update:location-details="$emit('update:location', $event)"
      />
    </div>

    <div class="flex-1">
      <TrLocationCombobox
        :model-value="unloadLocationOption"
        :location-details="unloadLocation"
        :label="$t('tender.create.form.unloadLocation')"
        :rules="[required()]"
        @update:model-value="$emit('update:unloadLocationOption', $event)"
        @update:location-details="$emit('update:unloadLocation', $event)"
      />
    </div>
  </div>

  <div class="flex flex-col md:flex-row md:space-x-3 space-y-6 md:space-y-0">
    <div class="flex-1">
      <TrLabeledSelect
        :model-value="paymentScheme"
        :label="$t('tender.create.form.paymentScheme')"
        :items="paymentSchemeOptions"
        class="flex-1"
        @update:model-value="$emit('update:paymentScheme', $event as typeof paymentScheme)"
      />
    </div>
    <div class="flex-1">
      <TrLabeledInput
        name="paymentAmount"
        :model-value="paymentAmountSuggestion"
        type="text"
        :label="$t('tender.create.form.paymentAmount')"
        :rules="[positiveDecimal()]"
        @update:model-value="$emit('update:paymentAmountSuggestion', $event)"
      />
    </div>
  </div>

  <MultiFrame>
    <template #top>
      <span class="bg-white px-3 text-base leading-6 text-gray-900">Fahrzeuge</span>
    </template>
    <div
      v-for="(slot, id) in slots"
      :key="id"
      class="flex flex-wrap"
      data-cy="slot"
    >
      <div
        class="w-1/2 pr-2 xl:w-[18%]"
        data-cy="time-from"
      >
        <TrTimeCombobox
          :ref="(el) => addSlotTimeFromCombobox(el, id)"
          name="timeFrom"
          :model-value="slot.timeFrom"
          class="w-full"
          :label="$t('tender.slot.timeFrom')"
          :rules="[required()]"
          :disabled="isSlotDisabled(slot)"
          :disabled-hint="$t('tender.update.disabledAccepted')"
          @update:model-value="updateSlot(id, 'timeFrom', $event as string)"
        />
      </div>

      <div
        class="w-1/2 pl-1 xl:pr-2 xl:w-[18%]"
        data-cy="time-to"
      >
        <TrTimeCombobox
          :model-value="slot.timeTo"
          class="w-full"
          :label="$t('tender.slot.timeTo')"
          :rules="[required()]"
          :disabled="isSlotDisabled(slot)"
          :disabled-hint="$t('tender.update.disabledAccepted')"
          @update:model-value="updateSlot(id, 'timeTo', $event as string)"
        />
      </div>

      <div class="w-full mt-4 xl:mt-0 lg:w-1/3 lg:pr-2 xl:pl-1 xl:w-[18%]">
        <TruckTypeSelect
          :model-value="slot.truckTypes"
          multiple
          :label="$t('tender.slot.truckType')"
          :rules="[required(), minSize(1)]"
          :disabled="isSlotDisabled(slot)"
          :disabled-hint="$t('tender.update.disabledAccepted')"
          @update:model-value="updateSlot(id, 'truckTypes', $event as typeof slot.truckTypes)"
        />
      </div>

      <div class="w-full mt-4 xl:mt-0 lg:w-1/3 lg:pr-2 xl:pl-1 xl:w-[18%]">
        <TruckBedTypeSelect
          :model-value="slot.truckBedTypes"
          multiple
          :label="$t('tender.slot.truckBedType')"
          :rules="[required(), minSize(1)]"
          :disabled="isSlotDisabled(slot)"
          :disabled-hint="$t('tender.update.disabledAccepted')"
          @update:model-value="updateSlot(id, 'truckBedTypes', $event as typeof slot.truckBedTypes)"
        />
      </div>

      <div class="w-full mt-4 xl:mt-0 lg:w-1/3 lg:pl-1 xl:pr-2 xl:w-auto xl:grow">
        <TruckFeatureSelect
          :model-value="slot.truckFeatures"
          multiple
          :label="$t('tender.slot.truckFeature')"
          :disabled="isSlotDisabled(slot)"
          :disabled-hint="$t('tender.update.disabledAccepted')"
          @update:model-value="updateSlot(id, 'truckFeatures', $event)"
        />
      </div>

      <div class="flex-none mt-4 xl:mt-0 xl:pl-1">
        <TrLabel
          :for="`slot-remove-${id}`"
          class="invisible hidden xl:block"
        >
          löschen
        </TrLabel>
        <div class="mt-1">
          <TrButton
            :id="`slot-remove-${id}`"
            :disabled="!allowSlotDeletion || isSlotDisabled(slot)"
            type="button"
            :disabled-hint="allowSlotDeletion ? $t('tender.update.disabledAccepted') : undefined"
            :color-scheme="ButtonColorScheme.TERTIARY"
            @click="removeSlot(id)"
          >
            <TrashIcon class="h-5" />
          </TrButton>
        </div>
      </div>
    </div>

    <template #bottom>
      <div class="px-3 bg-white">
        <TrButton
          type="button"
          :color-scheme="ButtonColorScheme.SECONDARY"
          @click="addSlotAndFocus"
        >
          {{ $t('tender.slot.add') }}
          <template #postfix>
            <PlusIcon class="w-5 h-5" />
          </template>
        </TrButton>
      </div>
    </template>
  </MultiFrame>
</template>
<script setup lang="ts">
import TrButton from '@app/support/TrButton.vue';
import TrLabeledSelect from '@app/forms/LabeledSelect.vue';
import TrLabel from '@app/forms/TrLabel.vue';
import TruckBedTypeSelect from '@app/tender/create/TruckBedTypeSelect.vue';
import TruckTypeSelect from '@app/tender/create/TruckTypeSelect.vue';
import TenderRoleSelect from '@app/tender/create/TenderRoleSelect.vue';
import TrTextarea from '@app/forms/TrTextarea.vue';
import DateInput from '@app/forms/DateInput.vue';
import PlusIcon from '@heroicons/vue/24/solid/PlusIcon';
import TrashIcon from '@heroicons/vue/24/outline/TrashIcon';
import TrLabeledInput from '@app/forms/LabeledInput.vue';
import TrTimeCombobox from '@app/support/TimeCombobox.vue';
import {required} from '@app/forms/rules/required';
import TrCompanyUsersComboBox from '@app/company/CompanyUsersCombobox.vue';
import TrLocationCombobox from '@app/forms/LocationCombobox.vue';
import {tenderRoles} from '@app/tender/TenderRole';
import Calendar from '@app/time/Calendar';
import {PaymentScheme} from '@app/tender/PaymentScheme';
import {LocationDetails, LocationOption} from '@app/forms/Location';
import {ComponentPublicInstance, computed, nextTick, reactive, toRefs} from 'vue';
import {UuidString} from '@app/uuid/UuidString';
import {ButtonColorScheme} from '@app/support/ButtonColorScheme';
import UuidFactory from '@app/uuid/UuidFactory';
import {truckTypes} from '@app/tender/trucks/TruckType';
import {truckBedTypes} from '@app/tender/trucks/TruckBedType';
import clock from '@app/time/Clock';
import {TenderParticipant, SlotForm} from '@app/tender/create/formTypes';
import {Uuid} from '@app/uuid/Uuid';
import {pickBy} from 'lodash';
import {minSize} from '@app/forms/rules/minSize';
import MultiFrame from '@app/forms/MultiFrame.vue';
import TruckFeatureSelect from '@app/tender/create/TruckFeatureSelect.vue';
import {positiveDecimal} from '@app/forms/rules/positiveDecimal';
import {usePaymentSchemeOptions} from '@app/payment/usePaymentSchemeOptions';

interface Props {
  participants?: Record<UuidString, TenderParticipant>,
  title?: string,
  description?: string,
  dateOfExecution?: Calendar,
  paymentScheme: PaymentScheme,
  paymentAmountSuggestion?: string,
  location?: LocationDetails,
  locationOption?: LocationOption
  unloadLocation?: LocationDetails,
  unloadLocationOption?: LocationOption
  slots?: Record<UuidString, SlotForm>
  allowEditingTakenSlots?: boolean
}

interface Events {
  (e: 'update:participants', v: Props['participants']): void
  (e: 'update:title', v: Props['title']): void
  (e: 'update:description', v: Props['description']): void
  (e: 'update:dateOfExecution', v: Props['dateOfExecution']): void
  (e: 'update:paymentScheme', v: Props['paymentScheme']): void
  (e: 'update:paymentAmountSuggestion', v: Props['paymentAmountSuggestion']): void
  (e: 'update:location', v: Props['location']): void
  (e: 'update:locationOption', v: Props['locationOption']): void
  (e: 'update:unloadLocation', v: Props['unloadLocation']): void
  (e: 'update:unloadLocationOption', v: Props['unloadLocationOption']): void
  (e: 'update:slots', v: Props['slots']): void
}

const props = withDefaults(defineProps<Props>(), {
    allowEditingTakenSlots: false,
    participants: undefined,
    title: undefined,
    description: undefined,
    dateOfExecution: undefined,
    paymentAmountSuggestion: undefined,
    location: undefined,
    locationOption: undefined,
    unloadLocation: undefined,
    unloadLocationOption: undefined,
    slots: undefined,
});

const {
  participants: participantsProp,
  slots,
} = toRefs(props);

const emit = defineEmits<Events>();

const participantComboboxes = reactive(new Map<UuidString, typeof TrCompanyUsersComboBox>);

const usedParticipantsIds = computed(() => {
  const participantIds = Object.values(participantsProp?.value ?? {})
      .filter(({user}) => !!user)
      .map(({user}) => user!.id.toString());

  return new Set(participantIds);
});

function addParticipantAndFocus() {
  const id = UuidFactory.v4();

  emit('update:participants', {
    ...participantsProp?.value,
    [id.toString()]: {
      role: tenderRoles.FOREMAN,
    },
  });

  // focus the new combobox
  nextTick(() => {
    participantComboboxes.get(id.toString())?.focus();
  });
}

function removeParticipant(participantId: UuidString) {
  emit('update:participants', pickBy(participantsProp?.value, (_, key) => key !== participantId));

  participantComboboxes.delete(participantId);
}

/** keep map of comboboxes for focusing */
function addParticipantCombobox(el: Element | ComponentPublicInstance | null, id: UuidString) {
  const combobbox = el as unknown as typeof TrCompanyUsersComboBox;
  participantComboboxes.set(id, combobbox);
}

function updateParticipant<T extends keyof TenderParticipant>(participantId: UuidString, prop: T, value: TenderParticipant[T]) {
  const updatedParticipant = {
    ...participantsProp!.value![participantId],
    [prop]: value,
  };


  emit('update:participants', {
    ...participantsProp?.value,
    [participantId]: updatedParticipant,
  });
}

const allowParticipantDeletion = computed(() => participantsProp?.value && Object.keys(participantsProp.value).length > 1);

const { paymentSchemeOptions } = usePaymentSchemeOptions();

const slotTimeFromComboboxes = reactive(new Map<UuidString, typeof TrTimeCombobox>);

function addSlot(): Uuid {
  const id = UuidFactory.v4();

  emit('update:slots', {
    ...slots?.value,
    [id.toString()]: makeEmptySlot(),
  });

  return id;
}

function addSlotAndFocus() {
  const id = addSlot();

  // focus the new combobox
  nextTick(() => {
    slotTimeFromComboboxes.get(id.toString())?.focus();
  });
}

function removeSlot(slotID: UuidString) {
  emit('update:slots', pickBy(slots?.value, (_, key) => key !== slotID));

  slotTimeFromComboboxes.delete(slotID);
}

/** keep map of comboboxes for focusing */
function addSlotTimeFromCombobox(el: Element | ComponentPublicInstance | null, id: UuidString) {
  const combobbox = el as unknown as typeof TrTimeCombobox;
  slotTimeFromComboboxes.set(id, combobbox);
}

function makeEmptySlot(): SlotForm {
  return {
    timeFrom: '08:00',
    timeTo: '17:00',
    truckFeatures: [],
    truckTypes: [truckTypes.AXLES_3],
    truckBedTypes: [truckBedTypes.TROUGH],
    isTaken: false,
  };
}

function updateSlot<T extends keyof SlotForm>(slotId: UuidString, prop: T, value: SlotForm[T]) {
  const updatedSlot = {
    ...slots!.value![slotId],
    [prop]: value,
  };


  emit('update:slots', {
    ...slots?.value,
    [slotId]: updatedSlot,
  });
}

const allowSlotDeletion = computed(() => slots?.value && Object.keys(slots.value).length > 1);

function isSlotDisabled(slot: SlotForm) {
  return slot.isTaken && !props.allowEditingTakenSlots;
}
</script>
