<template>
  <GenericErrorAlert
    v-if="isError"
  />

  <template v-else>
    <div class="flex items-center mb-6">
      <h3 class="flex-1 text-xl font-semibold leading-6 text-gray-900">
        {{ $t("truckOffer.search.title") }}
      </h3>
      <div class="flex flex-none">
        <TrButton
          :loading="fetchingActiveFilter"
          :disabled="fetchingActiveFilter"
          :color-scheme="ButtonColorScheme.SECONDARY"
          @click="showFilterModal = true"
        >
          <template
            v-if="filterCount > 0"
            #prefix
          >
            <span class="flex text-xs items-center justify-center bg-primary-50 rounded-full h-5 w-5">{{
              filterCount
            }}</span>
          </template>
          Filter
          <template #postfix>
            <AdjustmentsHorizontalIcon class="w-5 h-5" />
          </template>
        </TrButton>

        <TruckOfferFilterModal
          v-if="activeFilter"
          :loading="isPendingUpdateActiveFilter"
          :search-params="activeFilter"
          :show="showFilterModal"
          @search="onSearch"
          @hide="showFilterModal = false"
        />
      </div>
    </div>

    <div v-if="!isPending && truckOffers && truckOffers.length === 0">
      <NoData>
        <div class="text-center">
          {{ $t('truckOffer.search.empty') }}
        </div>
      </NoData>
    </div>

    <div
      v-if="!isPending && truckOffers && truckOffers.length > 0"
    >
      <TruckOfferList :truck-offers="truckOffers" />
      <PaginationFooter
        class="mt-6"
        :entity-label="$t('shell.navigation.truckOffers')"
        :offset-pagination-data="paginationData"
        :loading="isPending"
        @change-page="paginationData.currentPage.value = $event"
      />
    </div>
  </template>
</template>

<script setup lang="ts">
import {searchTruckOffers} from '@app/truckoffer/truckOfferService';
import {useAppShellBarLoader} from '@app/loader/useAppShellBarLoader';
import {computed, onMounted, ref} from 'vue';
import NoData from '@app/support/NoData.vue';
import PaginationFooter from '@app/pagination/PaginationFooter.vue';
import {useOffsetPagination} from '@app/pagination/useOffsetPagination';
import TruckOfferList from '@app/truckoffer/TruckOfferList.vue';
import clock from '@app/time/Clock';
import GenericErrorAlert from '@app/feedback/GenericErrorAlert.vue';
import {AdjustmentsHorizontalIcon} from '@heroicons/vue/24/outline';
import {ButtonColorScheme} from '@app/support/ButtonColorScheme';
import TrButton from '@app/support/TrButton.vue';
import {NotifiableTruckOfferSearchParams} from '@app/truckoffer/search/TruckOfferSearchParams';
import {boolSum} from '@app/support/boolSum';
import {isEqual, sortBy} from 'lodash';
import {truckTypeValues as availableTruckTypeValues} from '@app/tender/trucks/TruckType';
import TruckOfferFilterModal from '@app/truckoffer/search/TruckOfferFilterModal.vue';
import {useAsyncAction} from '@app/http/useAsyncAction';
import {
  fetchActiveTruckOfferFilter,
  updateActiveTruckOfferFilter,
} from '@app/truckoffer/filter/TruckOfferFilterService';
import {useMutation} from '@app/http/useMutation';

const currentPage = ref(1);
const showFilterModal = ref(false);

const {
  execute: fetchActiveFilter,
  data: activeFilter,
  isFetching: fetchingActiveFilter,
} = useAsyncAction(fetchActiveTruckOfferFilter);

const filterCount = computed(() => boolSum(
    activeFilter.value?.locationDetails,
    activeFilter.value?.fromDate,
    activeFilter.value?.toDate,
    // no truck types means all truck types and all truck types means no filter
    activeFilter.value?.truckTypes?.length !== 0 && !isEqual(sortBy(activeFilter.value?.truckTypes), sortBy(availableTruckTypeValues)),
));

const {execute: updateActiveFilter, isPending: isPendingUpdateActiveFilter} = useMutation(updateActiveTruckOfferFilter);

async function onSearch(searchParams: NotifiableTruckOfferSearchParams) {
  await updateActiveFilter(searchParams);
  showFilterModal.value = false;
  await fetchActiveFilter();
  await fetch();
}

const {isPending, isError, data: searchResult, isFetching, execute: fetch} = useAsyncAction(async function () {
  const todayMidnight = clock.now()
      .withHour(0)
      .withMinute(0);

  return await searchTruckOffers({
    latitude: activeFilter.value?.locationDetails?.latitude,
    longitude: activeFilter.value?.locationDetails?.longitude,
    fromDateTime: activeFilter.value?.fromDate ?? todayMidnight,
    toDateTime: activeFilter.value?.toDate,
    truckTypes: activeFilter.value?.truckTypes,
  });
});

const paginationData = useOffsetPagination({
  total: computed(() => searchResult.value?.totalItems ?? 0),
  pageSize: ref(25),
  currentPage: currentPage,
});

const truckOffers = computed(() => searchResult.value?.items);

useAppShellBarLoader(isFetching);

onMounted(async () => {
  await fetchActiveFilter();
  await fetch();
});
</script>
