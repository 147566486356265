<template>
  <template v-if="truckOffer">
    <div
      class="lg:flex lg:items-center lg:justify-between lg:space-x-5"
    >
      <div class="flex items-center space-x-5">
        <h1
          class="text-2xl font-bold text-gray-900"
          v-text="truckOffer?.title"
        />
      </div>

      <div
        :class="{
          'flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0': true,
          'mt-6 lg:mt-0': hasCancelPermissions || hasEditPermissions || hasApplyPermissions
        }"
      >
        <RequiresAuth>
          <TrButton
            v-if="hasCancelPermissions"
            :disabled="!isEditableStatus"
            :disabled-hint="$t('truckOffer.details.cancelIncorrectStatus')"
            :color-scheme="ButtonColorScheme.TERTIARY"
            @click="showTruckOfferCancelModal = true"
          >
            {{ $t('truckOffer.details.cancel') }}
          </TrButton>
        </RequiresAuth>

        <RequiresAuth>
          <TrButton
            v-if="hasEditPermissions"
            :disabled="!isEditableStatus"
            :disabled-hint="$t('truckOffer.details.editIncorrectStatus')"
            :color-scheme="ButtonColorScheme.TERTIARY"
            @click="updateTruckOffer"
          >
            {{ $t('truckOffer.details.edit') }}
          </TrButton>
        </RequiresAuth>

        <RequiresAuth>
          <TrButton
            v-if="hasApplyPermissions"
            :disabled="!truckOffer.allowsApplication"
            :disabled-hint="$t('truckOffer.details.applyNotPossible')"
            :color-scheme="ButtonColorScheme.PRIMARY"
            data-cy="truck-offer-apply-button"
            @click="startOfferApplication"
          >
            {{ $t('truckOffer.details.apply') }}
          </TrButton>
        </RequiresAuth>
      </div>
    </div>

    <TrSection label-id="truckoffer-details-info">
      <template #head>
        <h2
          id="truckoffer-details-info"
          class="text-lg leading-6 font-medium text-gray-900"
        >
          {{ $t('truckOffer.details.info.head') }}
        </h2>
      </template>

      <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div class="sm:col-span-2">
          <dt class="text-sm font-medium text-gray-500">
            {{ $t('truckOffer.details.info.truck') }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ truckText }}
          </dd>
        </div>

        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            {{ $t('truckOffer.details.info.location') }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ truckOffer.location?.displayName }}
            {{ $t('truckOffer.details.info.perimeter', {perimeter: truckOffer.maxRadius}) }}
          </dd>
        </div>

        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            {{ $t('truckOffer.details.info.time') }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ truckOffer.dateOfExecution.toHuman() }}
            <p>
              {{ truckOffer.timeFrom.toHuman() }}
              -
              {{ truckOffer.timeTo.toHuman() }}
            </p>
          </dd>
        </div>

        <div
          :class="{
            'sm:col-span-1': true,
            'hidden': !truckOffer.payPerHour
          }"
        >
          <dt class="text-sm font-medium text-gray-500">
            {{ $t('truckOffer.details.info.payPerHour') }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            <Money
              v-if="truckOffer.payPerHour && truckOffer.payPerHourAmount"
              :value="truckOffer.payPerHourAmount"
            />
          </dd>
        </div>

        <div
          :class="{
            'sm:col-span-1': true,
            'hidden': !truckOffer.payPerTon
          }"
        >
          <dt class="text-sm font-medium text-gray-500">
            {{ $t('truckOffer.details.info.payPerTon') }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            <Money
              v-if="truckOffer.payPerTon && truckOffer.payPerTonAmount"
              :value="truckOffer.payPerTonAmount"
            />
          </dd>
        </div>

        <div class="sm:col-span-2">
          <dt class="text-sm font-medium text-gray-500">
            {{ $t('truckOffer.details.info.description') }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ truckOffer.description }}
          </dd>
        </div>
      </dl>
    </TrSection>

    <TrSection
      label-id="truck-offer-partial-orders"
    >
      <template #head>
        <SectionHeading>{{ $t('truckOffer.details.partialOrders.title') }}</SectionHeading>
      </template>

      <p v-if="truckOffer.partialOrders.length === 0">
        {{ $t('truckOffer.details.partialOrders.empty') }}
      </p>

      <ul
        v-for="partialOrder in truckOffer.partialOrders"
        :key="partialOrder.id.toString()"
      >
        <TruckOfferPartialOrderComponent
          :truck-offer="truckOffer"
          :partial-order="partialOrder"
          @partial-order-accepted="acceptPartialOrder"
          @partial-order-rejected="rejectParialOrder"
          @partial-order-revoked="revokePartialOrder"
          @partial-order-confirmed="confirmPartialOrder"
        />
      </ul>
    </TrSection>
  </template>

  <TruckOfferCancelModal
    :show="showTruckOfferCancelModal"
    :loading="cancelFetching"
    @hide="showTruckOfferCancelModal = false"
    @cancel="doCancelTruckOffer"
  />

  <TruckOfferApplicationModal
    v-if="truckOffer"
    :suggested-payment-amount-per-hour="truckOffer.payPerHourAmount"
    :suggested-payment-amount-per-ton="truckOffer.payPerTonAmount"
    :show="showApplicationModal"
    :truck-offer-id="truckOffer?.id"
    :available-payment-schemes="availablePaymentSchemes"
    :estimate-costs="verifyCostInfo?.estimatedCosts"
    @hide="showApplicationModal = false"
    @dirty="reFetch"
  />

  <BillingAddressRequiredModal
    v-if="truckOffer"
    :show="showApplyBillingAddressRequiredModal"
    :create-link="{
      name: updateCompanyRoute.name,
      params: {companyId: auth.getCompanyId().toString()},
      query: {createTruckOfferPartialOrder: 1, truckOffer: truckOffer.id.toString()}
    }"
    @hide="showApplyBillingAddressRequiredModal = false"
  />

  <BillingAddressRequiredModal
    v-if="truckOffer"
    :show="!!acceptPartialOrderBillingAddressRequiredId"
    :create-link="{
      name: updateCompanyRoute.name,
      params: {companyId: auth.getCompanyId().toString()},
      query: {acceptTruckOfferPartialOrderId: acceptPartialOrderBillingAddressRequiredId?.toString(), truckOffer: truckOffer.id.toString()}
    }"
    @hide="acceptPartialOrderBillingAddressRequiredId = undefined"
  />
</template>

<script setup lang="ts">
import {
  acceptTruckOfferPartialOrder,
  cancelTruckOffer,
  confirmTruckOfferPartialOrder,
  fetchTruckOffer,
  rejectTruckOfferPartialOrder,
  revokeTruckOfferPartialOrder,
  verifyAcceptTruckOfferPartialOrder,
  verifyApplyForTruckOffer,
} from '@app/truckoffer/truckOfferService';
import {computed, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {Uuid} from '@app/uuid/Uuid';
import {useAppShellBarLoader} from '@app/loader/useAppShellBarLoader';
import TrSection from '@app/support/TrSection.vue';
import Money from '@app/money/money/Money.vue';
import {useTruckTranslations} from '@app/i18n/useTruckTranslations';
import {ButtonColorScheme} from '@app/support/ButtonColorScheme';
import RequiresAuth from '@app/auth/RequiresAuth.vue';
import TrButton from '@app/support/TrButton.vue';
import {TruckOfferStatus} from '@app/truckoffer/TruckOfferStatus';
import auth, {hasAuthToken, userCompanyId, userId, userPermissions} from '@app/auth/Auth';
import {permissions} from '@app/auth/permissions/permissions';
import {truckOfferRoles} from '@app/truckoffer/TruckOfferRole';
import {TruckOfferContactPerson} from '@app/truckoffer/TruckOfferContactPerson';
import {updateTruckOfferRoute} from '@app/truckoffer/routes';
import TruckOfferCancelModal from '@app/truckoffer/TruckOfferCancelModal.vue';
import TruckOfferApplicationModal from '@app/truckoffer/TruckOfferApplicationModal.vue';
import {PaymentScheme} from '@app/tender/PaymentScheme';
import {VerifyCostActionInfo} from '@app/tender/VerifyCostActionInfo';
import SectionHeading from '@app/support/SectionHeading.vue';
import TruckOfferPartialOrderComponent from '@app/truckoffer/TruckOfferPartialOrder.vue';
import {updateCompanyRoute} from '@app/company/routes';
import BillingAddressRequiredModal from '@app/billing/BillingAddressRequiredModal.vue';
import {urlParamRef} from '@app/routing/urlParamRef';
import {TruckOfferPartialOrder} from '@app/truckoffer/TruckOfferPartialOrder';
import {TruckOffer} from '@app/truckoffer/TruckOffer';
import {useQuery} from '@app/http/useQuery';
import {useMutation} from '@app/http/useMutation';

const route = useRoute();

const {truckFeaturesAnd, truckTypeTranslation, truckBedTypeTranslation} = useTruckTranslations();

const createPartialOrderParam = urlParamRef('createPartialOrder');
const acceptPartialOrderIdParam = urlParamRef('acceptPartialOrder');

const truckOfferId = computed(() => {
  const truckOfferIdRaw = route.params.id as string;
  return Uuid.fromString(truckOfferIdRaw);
});

const showTruckOfferCancelModal = ref(false);

/**
 * We might be coming from billing address update page which will append an url param, so we can continue creating po
 */
async function createPartialOrderFromUrlParam() {
  if (!createPartialOrderParam.value) {
    return;
  }

  createPartialOrderParam.value = undefined;
  showApplicationModal.value = true;
}

/**
 * We might be coming from billing address update page which will append an url param, so we can continue accepting po
 * @return {boolean} if a partial order has been accepted
 */
async function acceptPartialOrderFromUrlParam(truckOffer: TruckOffer): Promise<boolean> {
  if (acceptPartialOrderIdParam.value === undefined) {
    return false;
  }

  const partialOrder = truckOffer.partialOrders.find((partialOrder) => partialOrder.id.equals(Uuid.fromString(acceptPartialOrderIdParam.value!)));

  if (!partialOrder) {
    throw 'Partial order to accept could not be found!';
  }

  acceptPartialOrderIdParam.value = undefined;

  await acceptTruckOfferPartialOrder(truckOffer.id, partialOrder.id);

  return true;
}

const {reFetch, data: truckOffer, isPending} = useQuery(async function () {
  let truckOffer = await fetchTruckOffer(truckOfferId.value);

  await createPartialOrderFromUrlParam();
  const wasAccepted = await acceptPartialOrderFromUrlParam(truckOffer);

  if (wasAccepted) {
    truckOffer = await fetchTruckOffer(truckOfferId.value);
  }

  return truckOffer;
});

useAppShellBarLoader(isPending);

const isCreatorCompany = computed(() => {
  if (!truckOffer.value?.company.id) {
    return false;
  }

  return userCompanyId.value?.equals(truckOffer.value?.company.id);
});

const hasEditPermissions = computed(() => {
  if (!hasAuthToken.value) {
    return false;
  }

  if (!isCreatorCompany.value) {
    return false;
  }

  return isPrivilegedInTruckOffer()
      || userPermissions.value.has(permissions.MODIFY_TRUCK_OFFER);
});

const isEditableStatus = computed(() =>
    truckOffer.value?.status
    && [TruckOfferStatus.OPEN].includes(truckOffer.value.status),
);

const hasCancelPermissions = computed(() => {
  if (!hasAuthToken.value) {
    return false;
  }

  if (!isCreatorCompany.value) {
    return false;
  }

  return isPrivilegedInTruckOffer()
      || userPermissions.value.has(permissions.MODIFY_TRUCK_OFFER);
});

const hasApplyPermissions = computed(() => {
  if (!hasAuthToken.value) {
    return false;
  }

  return userPermissions.value.has(permissions.APPLY_FOR_TRUCK_OFFER);
});

const isPrivilegedInTruckOffer = () => {
  if (!truckOffer.value) {
    return false;
  }

  // TODO tenderDetails has "createdByCurrentUser"
  if (truckOffer.value?.createdBy.equals(userId.value)) {
    return true;
  }

  return truckOffer.value?.participants
      .filter(participant => participant.id.equals(userId.value))
      .some(isDriverOrDispatcher);
};

const isDriverOrDispatcher = (truckOfferParticipant: TruckOfferContactPerson) => [truckOfferRoles.DRIVER, truckOfferRoles.DISPATCHER].includes(truckOfferParticipant.role);

const router = useRouter();

function updateTruckOffer() {
  router.push({...updateTruckOfferRoute, params: {id: truckOffer.value?.id.toString()}});
}

const truckText = computed(() => {
  if (!truckOffer.value) {
    return '';
  }

  let truckText = `${truckTypeTranslation(truckOffer.value.truckType)}. ${truckBedTypeTranslation(truckOffer.value?.truckBedType)}.`;

  if (truckOffer.value.truckFeatures.length) {
    truckText += ` ${truckFeaturesAnd(truckOffer.value.truckFeatures)}`;
  }

  return truckText;
});

const {execute: doCancelTruckOffer, isPending: cancelFetching} = useMutation(async () => {
  if (!truckOffer.value) {
    throw new Error('Cannot cancel TruckOffer, not loaded!');
  }

  await cancelTruckOffer(truckOffer.value.id);
  await reFetch();
  showTruckOfferCancelModal.value = false;
});

const showApplyBillingAddressRequiredModal = ref(false);
const acceptPartialOrderBillingAddressRequiredId = ref<Uuid>();
const showApplicationModal = ref(false);

const verifyCostInfo = ref<VerifyCostActionInfo>();

const {execute: startOfferApplication} = useMutation(async () => {
  if (!userCompanyId.value) {
    throw new Error('Cannot apply for TruckOffer without company id!');
  }

  const verifyData = await verifyApplyForTruckOffer(truckOfferId.value, userCompanyId.value);

  if (!verifyData.hasAccountingInfo) {
    showApplyBillingAddressRequiredModal.value = true;
  } else {
    showApplicationModal.value = true;
  }
});

const availablePaymentSchemes = computed(() => {
  const availablePaymentSchemes = [];
  if (truckOffer.value?.payPerHour) {
    availablePaymentSchemes.push(PaymentScheme.PER_HOUR);
  }
  if (truckOffer.value?.payPerTon) {
    availablePaymentSchemes.push(PaymentScheme.PER_TON);
  }
  return availablePaymentSchemes;
});

const {
  execute: acceptPartialOrder,
} = useMutation(async (partialOrder: TruckOfferPartialOrder) => {
  if (!truckOffer.value?.id) throw 'Truck Offer not resolved yet!';

  const verifyData = await verifyAcceptTruckOfferPartialOrder(truckOffer.value?.id, partialOrder.id);

  if (!verifyData.hasAccountingInfo) {
    acceptPartialOrderBillingAddressRequiredId.value = partialOrder.id;
  } else {
    await acceptTruckOfferPartialOrder(truckOffer.value?.id, partialOrder.id);
    await reFetch();
  }
});

const {
  execute: rejectParialOrder,
} = useMutation(async (partialOrder: TruckOfferPartialOrder) => {
  if (!truckOffer.value?.id) throw 'Truck Offer not resolved yet!';

  await rejectTruckOfferPartialOrder(truckOffer.value?.id, partialOrder.id);
  await reFetch();
});

const {
  execute: revokePartialOrder,
} = useMutation(async (partialOrder: TruckOfferPartialOrder) => {
  if (!truckOffer.value?.id) throw 'Truck Offer not resolved yet!';

  await revokeTruckOfferPartialOrder(truckOffer.value?.id, partialOrder.id);
  await reFetch();
});

const {
  execute: confirmPartialOrder,
} = useMutation(async (partialOrder: TruckOfferPartialOrder) => {
  if (!truckOffer.value?.id) throw 'Truck Offer not resolved yet!';

  await confirmTruckOfferPartialOrder(truckOffer.value?.id, partialOrder.id);
  await reFetch();
});
</script>
