<template>
  <SimpleModal
    :title="$t('tender.acceptModal.title')"
    :show="show"
    width-class="max-w-lg w-full lg:max-w-6xl"
    data-ct="tender-accept-modal"
    @hide="closeModal"
  >
    <span class="text-gray-500">
      {{ $t('tender.acceptModal.sub') }}
    </span>
    <TrForm
      id="accept-tender-form"
      class="flex flex-col space-y-4"
      @validated="submit"
    >
      <div class="w-full lg:w-1/3 mt-8">
        <TrLabeledInput
          v-model="paymentAmount"
          :rules="[required(), positiveDecimal()]"
          min="1"
          :label="$t('tender.acceptModal.paymentAmount') + ' ' + $t('tender.paymentScheme.' + paymentScheme.toLowerCase())"
          type="text"
          data-ct="tender-accept-modal-payment-amount"
        />
      </div>

      <div
        v-if="tenderPaymentAmountDiff < 0"
        class="flex items-center"
      >
        <InformationCircleIcon
          class="h-6 w-6 text-blue-600"
          aria-hidden="true"
        />
        <span class="ml-2">
          {{ $t('tender.acceptModal.paymentAmountLessThanTender') }}
        </span>
      </div>

      <TrTextarea
        v-model="message"
        name="message"
        :label="$t('tender.acceptModal.message')"
        data-ct="tender-accept-modal-message"
      />
    </TrForm>

    <Alert
      v-if="estimateCosts?.toNumber() !== 0"
      class="mt-4"
      :title="$t('tender.page.costInfo.head')"
      :mode="AlertMode.INFO"
    >
      <template #sub>
        {{ $t('tender.page.costInfo.explain1') }} <TrMoney
          v-if="estimateCosts"
          :value="estimateCosts.abs()"
        /> {{ $t('tender.page.costInfo.explain2') }}
      </template>
    </Alert>
    <template #actions>
      <div class="mb-4 sm:mb-0">
        <TrButton
          class="w-full"
          data-ct="tender-accept-modal-submit"
          type="submit"
          form="accept-tender-form"
        >
          {{ $t('generic.send') }}
        </TrButton>
      </div>
      <div class="sm:mr-4">
        <TrButton
          class="w-full"
          data-ct="tender-accept-modal-close"
          :color-scheme="ButtonColorScheme.TERTIARY"
          @click="closeModal"
        >
          {{ $t('generic.close') }}
        </TrButton>
      </div>
    </template>
  </SimpleModal>
</template>

<script lang="ts" setup>
import SimpleModal from '@app/overlays/modals/SimpleModal.vue';
import {Uuid} from '@app/uuid/Uuid';
import auth from '@app/auth/Auth';
import TrButton from '@app/support/TrButton.vue';
import {ButtonColorScheme} from '@app/support/ButtonColorScheme';
import TrForm from '@app/forms/TrForm.vue';
import {computed, ref, toRefs} from 'vue';
import TrTextarea from '@app/forms/TrTextarea.vue';
import tenderService from '@app/tender/TenderService';
import {required} from '@app/forms/rules/required';
import TrLabeledInput from '@app/forms/LabeledInput.vue';
import {PaymentScheme} from '@app/tender/PaymentScheme';
import {Money} from '@app/money/Money';
import {InformationCircleIcon} from '@heroicons/vue/24/outline';
import {positiveDecimal} from '@app/forms/rules/positiveDecimal';
import Alert from '@app/feedback/Alert.vue';
import {AlertMode} from '@app/feedback/AlertMode';
import {useAsyncAction} from '@app/http/useAsyncAction';
import TrMoney from '@app/money/money/Money.vue';

interface Props {
    show: boolean,
    tenderId: Uuid,
    slotId?: Uuid,
    paymentScheme: PaymentScheme
    suggestedPaymentAmount?: Money
    estimateCosts?: Money
}

interface Events {
    (e: 'hide'): void,
    (e: 'dirty'): void,
}

const props = defineProps<Props>();
const emit = defineEmits<Events>();

const {
    tenderId,
    slotId,
    paymentScheme,
    suggestedPaymentAmount,
} = toRefs(props);

const message = ref('');
const paymentAmount = ref();

const closeModal = () => {
    message.value = '';
    paymentAmount.value = suggestedPaymentAmount?.value?.toBig().toNumber();
    emit('hide');
};

const {execute: submit} = useAsyncAction(async () => {
    if (!slotId?.value) {
        return;
    }

    await tenderService.accept(tenderId.value, slotId.value, auth.getCompanyId(), message.value, Number(paymentAmount.value));

    emit('dirty');
    closeModal();
});

const tenderPaymentAmountDiff = computed(() => {
    if (!suggestedPaymentAmount?.value) {
        return 0;
    }

    if (!paymentAmount.value) {
        return 0;
    }

    return paymentAmount.value - suggestedPaymentAmount.value?.toNumber();
});
</script>
