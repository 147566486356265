import {RouteRecordRaw} from 'vue-router';
import UserPreferences from '@app/preferences/UserPreferences.vue';
import DeactivateMailNotifications from '@app/preferences/DeactivateMailNotifications.vue';

export const userPreferencesRoute: RouteRecordRaw = {
    path: '/users/:userId/preferences/',
    component: UserPreferences,
    name: 'userPreferences',
};

export const deactivateMailNotificationsRoute: RouteRecordRaw = {
    path: '/deactivate-mail-notification/:mailType',
    component: DeactivateMailNotifications,
    name: 'deactivateMailNotifications',
    meta: {
        blank: true,
    },
};

export const userPreferencesRoutes = [
    userPreferencesRoute,
    deactivateMailNotificationsRoute,
];
