<template>
  <template v-if="!isFetchingInitialPreferences">
    <TrForm
      id="create-tender-form"
      @validated="updatePreferences"
    >
      <TrSection
        label-id="settings-label"
        with-bottom
      >
        <template #head>
          <SectionHeading id="settings-label">
            {{ t('userPreferences.heading') }}
          </SectionHeading>
        </template>

        <Checkbox
          id="subscribe-to-status-updates"
          :checked="statusChangeNotificationEnabled"
          :disabled="isFetching"
          @change="statusChangeNotificationEnabled = !statusChangeNotificationEnabled"
        >
          {{ t('userPreferences.form.subscribeToStatusChangeEmails') }}
        </checkbox>

        <template #bottom>
          <div class="flex justify-end">
            <TrButton
              class="flex-grow lg:flex-none"
              :loading="isFetching"
              type="submit"
            >
              {{ $t('userPreferences.submit') }}
            </TrButton>
          </div>
        </template>
      </TrSection>
    </TrForm>
  </template>
</template>

<script setup lang="ts">
import TrSection from '@app/support/TrSection.vue';
import SectionHeading from '@app/support/SectionHeading.vue';
import {useI18n} from 'vue-i18n';
import TrForm from '@app/forms/TrForm.vue';
import {useAsyncAction} from '@app/http/useAsyncAction';
import {fetchUserPreferences, updateUserPreferences} from '@app/preferences/userPreferencesService';
import {useAppShellBarLoader} from '@app/loader/useAppShellBarLoader';
import {computed, ref} from 'vue';
import Checkbox from '@app/forms/checkbox/Checkbox.vue';
import TrButton from '@app/support/TrButton.vue';
import {Uuid} from '@app/uuid/Uuid';
import {useRoute} from 'vue-router';

const {t} = useI18n();

const route = useRoute();
const userId = computed(() => Uuid.fromString(route.params.userId as string));

const statusChangeNotificationEnabled = ref(false);

const {execute: fetchPreferences, isPending: isFetchingInitialPreferences, isFetching: isFetchingPreferences} = useAsyncAction(async function () {
  const userPreferences = await fetchUserPreferences(userId.value);

  statusChangeNotificationEnabled.value = userPreferences.statusChangeNotificationEnabled;
});

fetchPreferences();

const {execute: updatePreferences, isFetching: isUpdating} = useAsyncAction(async function () {
  await updateUserPreferences(userId.value, {
    statusChangeNotificationEnabled: statusChangeNotificationEnabled.value,
  });

  await fetchPreferences();
});

const isFetching = computed(() => isUpdating.value || isFetchingPreferences.value);

useAppShellBarLoader(isFetching);
</script>
