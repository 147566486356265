<template>
  <div class="flex-1">
    <TrRequiresAuth>
      <TrSidebarItem
        :to="{ path: '/' }"
        :name="$t('shell.navigation.dashboard')"
        :icon="HomeIcon"
        @click="$emit('close')"
      />

      <sidebar-divider>{{ $t("shell.navigation.tenderGroup") }}</sidebar-divider>

      <TrSidebarItem
        :to="{ path: '/tenders' }"
        :name="$t('shell.navigation.tenders')"
        :icon="MagnifyingGlassIcon"
        @click="$emit('close')"
      />

      <TrSidebarItem
        :to="{ path: '/mytenders' }"
        :name="$t('shell.navigation.mytenders')"
        :icon="ClipboardDocumentListIcon"
        @click="$emit('close')"
      />

      <sidebar-divider>{{ $t("shell.navigation.truckOfferGroup") }}</sidebar-divider>

      <TrSidebarItem
        :to="{ path: '/truckoffers' }"
        :name="$t('shell.navigation.truckOffers')"
        :icon="MagnifyingGlassIcon"
        @click="$emit('close')"
      />

      <TrSidebarItem
        :to="{ path: '/mytruckoffers' }"
        :name="$t('shell.navigation.myTruckOffers')"
        :icon="TruckIcon"
        @click="$emit('close')"
      />

      <sidebar-divider>{{ $t("shell.navigation.administrationGroup") }}</sidebar-divider>

      <TrSidebarItem
        :to="{ path: '/company' }"
        :name="$t('shell.navigation.company')"
        :icon="UsersIcon"
        @click="$emit('close')"
      />

      <TrSidebarItem
        :to="{ path: '/bills' }"
        :name="$t('shell.navigation.bills')"
        :icon="BanknotesIcon"
        @click="$emit('close')"
      />

      <TrSidebarItem
        :to="{ path: `/users/${userId?.toString()}/preferences/` }"
        :name="$t('shell.navigation.userPreferences')"
        :icon="AdjustmentsHorizontalIcon"
        @click="$emit('close')"
      />

      <RequiresPermissions :permissions="systemPermissions">
        <TrSidebarItem
          data-cy="registered-companies-menu-item"
          :to="{ path: '/reporting/registered-companies' }"
          name="Alle Unternehmen"
          :icon="BuildingOfficeIcon"
          @click="$emit('close')"
        />
      </RequiresPermissions>
    </TrRequiresAuth>
  </div>

  <TrSidebarItem
    to="mailto:support@transport-x.de"
    :name="$t('shell.navigation.help')"
    :icon="QuestionMarkCircleIcon"
    @click="$emit('close')"
  />
</template>

<script lang="ts" setup>
import TrSidebarItem from '@app/shell/SidebarItem.vue';
import {
  ClipboardDocumentListIcon,
  HomeIcon,
  UsersIcon,
  MagnifyingGlassIcon,
  BanknotesIcon,
  QuestionMarkCircleIcon,
  TruckIcon,
  BuildingOfficeIcon,
  AdjustmentsHorizontalIcon,
} from '@heroicons/vue/24/outline';
import TrRequiresAuth from '@app/auth/RequiresAuth.vue';
import RequiresPermissions from '@app/auth/RequiresPermissions.vue';
import {permissions} from '@app/auth/permissions/permissions';
import SidebarDivider from '@app/shell/SidebarDivider.vue';
import {userId} from '@app/auth/Auth';

interface Events {
  (e: 'close'): void
}

defineEmits<Events>();

const systemPermissions = new Set([permissions.SYSTEM_READ_REGISTERED_COMPANIES]);
</script>
